import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { RiProhibitedLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Flex,
  HStack,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";

import { AttributeField } from "@bucketco/shared/attributeFilter";
import {
  emptyFilter,
  UIFilterType,
  wrapWithFilterGroup,
} from "@bucketco/shared/filter";
import { CreateFlagVersionArgsType, Flag } from "@bucketco/shared/flagAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import { AndOrList } from "@/common/components/AndOrList";
import { DeleteIconButton } from "@/common/components/CommonIconButtons";
import {
  FilterGroup,
  SupportedFlagFilterTypes,
} from "@/common/components/filters/FilterGroup";
import FormNumberInput from "@/common/components/Form/FormNumberInput";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import { useDebounce } from "@/common/hooks/useDebounce";
import useAllSegment from "@/company/data/useAllSegment";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useTargetAudienceEstimate } from "../data/useTargetAudienceEstimate";

function adjustFlagAttributeFields(
  filterType: UIFilterType,
  fields: AttributeField[],
) {
  if (filterType === "userAttribute") {
    fields = [
      {
        key: "id",
        label: "User ID",
        type: "text",
        icon: "id",
        system: true,
      },
      ...fields.filter((f) => f.key !== "id"),
    ];
  }

  return fields;
}

const SegmentStatelessRestrictionsWarning = () => (
  <Text fontSize="xs" lineHeight={1.5}>
    Segments with <strong>First seen</strong>, <strong>Last seen</strong> or{" "}
    <strong>Feature metric</strong> filters are not supported for targeting
    rules.
  </Text>
);

type FlagRuleFormProps = {
  envId: string;
  flagId: string;
  ruleId: string;
  ruleIndex: number;
  remove: () => void;
};

function FlagRuleForm({
  envId,
  flagId,
  ruleId,
  ruleIndex,
  remove,
}: FlagRuleFormProps) {
  const rule = useWatch<CreateFlagVersionArgsType, `rules.${number}`>({
    name: `rules.${ruleIndex}`,
  });

  const allSegment = useAllSegment();

  const partialRolloutThreshold = useDebounce(
    rule?.partialRolloutThreshold ?? 0,
    500,
  );

  const { data: estimate, isLoading: estimateLoading } =
    useTargetAudienceEstimate(
      envId,
      flagId,
      ruleId,
      rule
        ? {
            filter: rule.filter,
            partialRolloutThreshold,
          }
        : undefined,
    );

  return (
    <Box key={ruleId} alignSelf="stretch" as="fieldset">
      <VStack alignItems="start">
        <VStack alignItems="stretch">
          <Text fontWeight="medium">Rule conditions</Text>
          <ManagedFormControl
            name={`rules.${ruleIndex}.filter`}
            render={({ field: { value, onChange } }) => (
              <FilterGroup
                adjustAttributeFields={adjustFlagAttributeFields}
                context="flag"
                segmentItemFilter={(s) => s.stateless}
                segmentItemFilterReason={() => (
                  <SegmentStatelessRestrictionsWarning />
                )}
                showItemsIcons={true}
                types={SupportedFlagFilterTypes}
                value={wrapWithFilterGroup(value)}
                onChange={onChange}
              />
            )}
          />
        </VStack>

        <FormNumberInput
          _label={{ fontWeight: "medium" }}
          fontSize="sm"
          label="Rollout percentage"
          max={100}
          min={0}
          name={`rules.${ruleIndex}.partialRolloutThreshold`}
          scaleFactor={0.001} // 0-100% = 0-100000
          unit="%"
          w="24"
        />
      </VStack>

      <Box position="absolute" right={3} top={3}>
        <HStack fontSize="sm" spacing={3}>
          {estimate && allSegment ? (
            <HStack fontSize="sm" spacing={1}>
              <Text as="span" fontWeight="medium">
                {estimate.count}
              </Text>
              <Text as="span" color="dimmed">
                of {allSegment.segmentCount} estimated companies
              </Text>
            </HStack>
          ) : (
            estimateLoading && <Spinner color="dimmed" size="xs" />
          )}

          <DeleteIconButton
            aria-labelledby={`button-${ruleId} legend-${ruleId}`}
            color="dimmed"
            id={`button-${ruleId}`}
            label="Remove"
            onClick={() => remove()}
          />
        </HStack>
      </Box>
    </Box>
  );
}

export default function FlagRulesForm({ flag }: { flag: Flag }) {
  const { currentEnv } = useAuthContext();
  const form = useFormContext<CreateFlagVersionArgsType>();
  const rules = useFieldArray<CreateFlagVersionArgsType>({
    control: form.control,
    name: "rules",
  });

  const currentFlagVersion = flag.currentVersions.find(
    (v) => v.environment.id === currentEnv?.id,
  );
  const currentFlagVersionNumber = currentFlagVersion?.version ?? "0";

  return (
    <VStack alignItems="stretch">
      <Flex direction="row" justify="space-between">
        <EnvironmentDisplayName environment={currentEnv!} />
        {currentFlagVersion ? (
          <Text
            as={Link}
            fontSize="sm"
            textDecoration="underline"
            to="versions"
          >
            Version #{currentFlagVersionNumber}
          </Text>
        ) : (
          <Text color="dimmed" fontSize="sm">
            No versions yet
          </Text>
        )}
      </Flex>

      {rules.fields.length === 0 ? (
        <Card p={4}>
          <HStack color="dimmed" spacing={1.5}>
            <RiProhibitedLine />
            <Text fontSize="sm">No one has access</Text>
          </HStack>
        </Card>
      ) : (
        <AndOrList
          conjunction="or"
          conjunctionProps={{ ml: 4, my: -1, zIndex: 1 }}
          direction="vertical"
          gap="0"
        >
          {rules.fields.map((rule, index) => (
            <Card key={rule.id} p={4} w="full">
              <FlagRuleForm
                envId={currentEnv!.id}
                flagId={flag.id}
                remove={() => rules.remove(index)}
                ruleId={rule.id}
                ruleIndex={index}
              />
            </Card>
          ))}
        </AndOrList>
      )}

      <Box position="relative">
        <ButtonGroup display="flex" position="relative">
          <Button
            background="appBackground"
            isDisabled={form.formState.isSubmitting}
            size="sm"
            variant="outline"
            onClick={() =>
              rules.append({
                partialRolloutThreshold: 100000,
                filter: emptyFilter,
              })
            }
          >
            Add rule
          </Button>
        </ButtonGroup>
      </Box>
    </VStack>
  );
}
