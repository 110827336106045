import { useToast } from "@chakra-ui/react";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { FeatureName } from "@bucketco/shared/featureAPI";
import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";

import featureViewsQueryKeys from "@/app/data/featureViewsQueryKeys";
import { useAuthContext } from "@/auth/contexts/authContext";
import { UpsertForm } from "@/common/types/upsertForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useFeaturesTableState } from "@/feature/hooks/useFeaturesTableState";

export const useFeatureViewUpsertMutations = (
  view: FeatureViewDTO,
  features: FeatureName[],
  options?: Omit<
    UseMutationOptions<FeatureViewDTO, AxiosError<ErrorResponse>, UpsertForm>,
    "mutationFn"
  >,
) => {
  const { currentApp } = useAuthContext();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { sorting, onSortingChange, columnStates, onColumnStatesChange } =
    useFeaturesTableState(view);

  return useMutation({
    ...options,
    mutationFn: async (values: UpsertForm) => {
      if (values.action === "update") {
        return api
          .patch<"/apps/:appId/feature-views/:viewId">(
            `/apps/${currentApp?.id}/feature-views/${view.id}`,
            {
              columns: columnStates,
              columnSortKey: sorting[0]?.id,
              columnSortDesc: sorting[0]?.desc,
            },
          )
          .then((res) => res.data);
      } else {
        // Create a new feature view
        return api
          .post<"/apps/:appId/feature-views">(
            `/apps/${currentApp?.id}/feature-views`,
            {
              name: values.name,
              featureIds: features.map((f) => f.id),
              columns: columnStates,
              columnSortKey: sorting[0]?.id,
              columnSortDesc: sorting[0]?.desc,
            },
          )
          .then((res) => res.data);
      }
    },
    onSuccess: async (data, variables, context) => {
      segmentAnalytics.track("Column saved", {
        view: "feature view",
      });

      toast({
        title:
          variables.action === "update"
            ? "Feature view updated"
            : "New feature view created",
        status: "success",
        duration: 2000,
        isClosable: true,
      });

      onSortingChange([]);
      onColumnStatesChange([]);

      // Wait for feature views to be refetched
      await queryClient.invalidateQueries({
        queryKey: featureViewsQueryKeys.list(currentApp?.id),
      });

      options?.onSuccess?.(data, variables, context);
    },
  });
};
