import {
  CompaniesQuery,
  CompanyFeaturesQuery,
  CompanyFeatureUsersQuery,
  CompanyUsersQuery,
} from "@bucketco/shared/companyAPI";

export const companyQueryKeys = {
  list: (
    appId?: string,
    envId?: string,
    params?: Omit<CompaniesQuery, "envId">,
  ) =>
    params
      ? (["apps", appId, "environments", envId, "companies", params] as const)
      : (["apps", appId, "environments", envId, "companies"] as const),

  single: (appId?: string, envId?: string, companyId?: string) =>
    [...companyQueryKeys.list(appId, envId), companyId] as const,

  listFeatures: (
    appId?: string,
    envId?: string,
    companyId?: string,
    params?: Omit<CompanyFeaturesQuery, "envId">,
  ) =>
    params
      ? ([
          ...companyQueryKeys.single(appId, envId, companyId),
          "features",
          params,
        ] as const)
      : ([
          ...companyQueryKeys.single(appId, envId, companyId),
          "features",
        ] as const),

  listUsers: (
    appId?: string,
    envId?: string,
    companyId?: string,
    params?: Omit<CompanyUsersQuery, "envId">,
  ) =>
    params
      ? ([
          ...companyQueryKeys.single(appId, envId, companyId),
          "users",
          params,
        ] as const)
      : ([
          ...companyQueryKeys.single(appId, envId, companyId),
          "users",
        ] as const),

  listFeatureUsers: (
    appId?: string,
    envId?: string,
    companyId?: string,
    featureId?: string,
    params?: Omit<CompanyFeatureUsersQuery, "envId">,
  ) =>
    params
      ? ([
          ...companyQueryKeys.listUsers(appId, envId, companyId),
          "features",
          featureId,
          params,
        ] as const)
      : ([
          ...companyQueryKeys.listUsers(appId, envId, companyId),
          "features",
          featureId,
        ] as const),

  attributes: (appId?: string, envId?: string) =>
    [...companyQueryKeys.list(appId, envId), "attributes"] as const,

  companyNames: (
    appId?: string,
    envId?: string,
    params: Record<string, any> = {},
  ) =>
    params
      ? ([...companyQueryKeys.list(appId, envId), "names", params] as const)
      : ([...companyQueryKeys.list(appId, envId), "names"] as const),
};
