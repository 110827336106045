import { Helmet } from "react-helmet-async";
import { FaGoogle } from "react-icons/fa";
import { Link as RouterLink } from "react-router-dom";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import { z } from "zod";

import { redirectUrlSchema } from "@bucketco/shared/schemas/redirectUrlSchema";
import { SignupUrl } from "@bucketco/shared/urls";

import CenteredLayout from "@/common/components/CenteredLayout";
import { API_URL } from "@/common/utils/env";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export const loginErrorMessagesSchema = z.enum([
  "only_company_emails_error",
  "no_email_error",
]);

export type LoginErrorMessages = z.infer<typeof loginErrorMessagesSchema>;

const messageTypeToMessageMap: {
  [key in LoginErrorMessages]: React.ReactNode;
} = {
  no_email_error: "Invalid email address",
  only_company_emails_error: (
    <>
      Only work emails are allowed.
      <br />
      Please try again with your work email.
    </>
  ),
};

export default function PassportLogin() {
  const locationSearchParams = new URLSearchParams(window.location.search);
  const parsedRedirect = redirectUrlSchema.safeParse(
    locationSearchParams.get("redirect_url"),
  );
  const errorMessageTypeParseResult = loginErrorMessagesSchema.safeParse(
    locationSearchParams.get("error"),
  );
  const errorMessage = errorMessageTypeParseResult.success
    ? messageTypeToMessageMap[errorMessageTypeParseResult.data]
    : null;

  const redirect = parsedRedirect.success ? parsedRedirect.data : "/";

  const queryParams = new URLSearchParams({
    redirect_url: redirect,
  });

  const isInvited = redirect.includes("/invite/");

  const loginUrl = `${API_URL}/auth/google?${queryParams.toString()}`;

  let header, text;

  if (isInvited) {
    header = "You're invited";
    text = "To accept your invitation, sign in below.";
  } else {
    header = "Welcome back";
  }

  return (
    <CenteredLayout showBucketLogo>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Box>
        <Flex alignItems="center" direction="column" gap={8} maxW="sm">
          <VStack spacing={3}>
            <Heading fontSize="3xl">{header}</Heading>
            {text && (
              <Text
                color="dimmed"
                fontSize="sm"
                fontWeight="medium"
                textAlign="center"
              >
                {text}
              </Text>
            )}
            <HStack fontSize="sm" spacing={1}>
              <Text color="dimmed" fontWeight="medium">
                Don&apos;t have an account?
              </Text>
              <Link
                as={RouterLink}
                fontWeight="medium"
                to={SignupUrl(queryParams)}
              >
                Sign up
              </Link>
            </HStack>
          </VStack>
          <Flex direction="column" gap={6} p={8} px={12}>
            {errorMessage ? (
              <Alert status="warning">
                <AlertIcon />
                {errorMessage}
              </Alert>
            ) : null}
            <VStack spacing={3}>
              <Button
                as="a"
                href={loginUrl}
                leftIcon={<FaGoogle />}
                size="lg"
                variant="solid"
                onClick={() => {
                  segmentAnalytics.track(
                    "Google Authentication Button Clicked",
                  );
                }}
              >
                Sign in with Google
              </Button>
            </VStack>
            <Text
              color="dimmed"
              fontSize="xs"
              fontWeight="medium"
              textAlign="center"
            >
              By continuing, you&apos;re agreeing to our{" "}
              <Link href="https://bucket.co/pages/policies" target="_blank">
                policies
              </Link>
              .
            </Text>
          </Flex>
        </Flex>
      </Box>
    </CenteredLayout>
  );
}
