import { ReactNode, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { RiChat1Line, RiFlag2Line } from "react-icons/ri";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  CreateFeatureArgsSchema,
  CreateFeatureArgsType,
  FeatureDetail,
} from "@bucketco/shared/featureAPI";
import { FeatureUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import FeatureIcon from "@/common/assets/feature-icon.svg?react";
import STARSIcon from "@/common/assets/stars-icon.svg?react";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import ModalCancelButton from "@/common/components/ModalCancelButton";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { CreateAffectEnvironments } from "@/environment/components/EnvironmentCallouts";
import { NewFeatureForm } from "@/feature/components/NewFeatureForm";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export default function NewFeature({
  isOpen = true,
  footerDetails,
  onClose,
  onSuccess,
}: {
  isOpen?: boolean;
  footerDetails?: ReactNode;
  onClose?: () => void;
  onSuccess?: (feature: FeatureDetail) => void;
}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isOpen) return;
    segmentAnalytics.track(`Create Feature Opened`);
  }, [isOpen]);

  const highlightTextColor = useColorModeValue("brand.600", "brand.300");

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          navigate({
            pathname: "..",
            search: searchParams.toString(),
          });
        }
      }}
    >
      <ModalOverlay />
      <ModalContent
        alignItems="stretch"
        bg="appBackground"
        display="flex"
        flexDirection="row"
        gap={0}
        justifyContent="stretch"
        maxW="auto"
        pt={0}
        w="auto"
      >
        <Flex direction="column" gap={8} pt={8} w="sm">
          <ModalHeader>
            <VStack align="start">
              <HStack>
                <FeatureIcon height={22} width={22} />
                <Heading>New feature</Heading>
              </HStack>
            </VStack>
          </ModalHeader>
          <NewFeatureBody
            footerDetails={footerDetails}
            onClose={onClose}
            onSuccess={onSuccess}
          />
        </Flex>
        <Flex
          align="center"
          bg="appSidebarBackground"
          borderLeft="1px solid"
          borderLeftColor="appBorder"
          borderRightRadius="md"
          flexGrow={1}
          p={8}
          pos="relative"
          w="2xs"
        >
          <ModalCloseButton />
          <Flex as="ul" direction="column" fontSize="sm" gap={4} w="52">
            <Flex as="li" direction="row" gap={2} m={0} p={0}>
              <Box color={highlightTextColor} pt={0.5}>
                <RiFlag2Line size={16} />
              </Box>
              <Flex direction="column" gap={1}>
                <Text color={highlightTextColor} fontWeight="medium">
                  Feature flagging
                </Text>
                <Text color="dimmed" sx={{ textWrap: "balance" }}>
                  Roll out features without risk
                </Text>
              </Flex>
            </Flex>
            <Flex as="li" direction="row" gap={2} m={0} p={0}>
              <Box color={highlightTextColor} pt={0.5}>
                <STARSIcon height="16px" width="16px" />
              </Box>
              <Flex direction="column" gap={1}>
                <Text color={highlightTextColor} fontWeight="medium">
                  Adoption metrics
                </Text>
                <Text color="dimmed" sx={{ textWrap: "balance" }}>
                  Track STARS metrics and set goals
                </Text>
              </Flex>
            </Flex>
            <Flex as="li" direction="row" gap={2} m={0} p={0}>
              <Box color={highlightTextColor} pt={0.5}>
                <RiChat1Line size={16} />
              </Box>
              <Flex direction="column" gap={1}>
                <Text color={highlightTextColor} fontWeight="medium">
                  Qualitative feedback
                </Text>
                <Text color="dimmed" sx={{ textWrap: "balance" }}>
                  Automated customer surveys at the right time
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}

export function NewFeatureBody({
  footerDetails,
  onClose,
  onSuccess,
}: {
  footerDetails?: ReactNode;
  onClose?: () => void;
  onSuccess?: (feature: FeatureDetail) => void;
}) {
  const { currentApp, currentEnv } = useAuthContext();
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const cancelHoverColor = useColorModeValue("gray.700", "gray.300");

  const { form, handleSubmit } = useApiForm(
    async (formData: CreateFeatureArgsType) => {
      return api
        .post<"/apps/:appId/features">(
          `/apps/${currentApp?.id}/features`,
          formData,
          { params: { envId: currentEnv!.id } },
        )
        .then((response) => response.data.feature);
    },
    CreateFeatureArgsSchema,
    {
      onSuccess: (feature, formValues) => {
        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.listNames(currentApp?.id),
        });
        queryClient.setQueryData(
          featureQueryKeys.single(currentApp?.id, feature.id),
          feature,
        );
        queryClient.setQueryData(
          featureQueryKeys.single(currentApp?.id, currentEnv?.id, feature.id),
          feature,
        );

        queryClient.invalidateQueries({
          queryKey: featureQueryKeys.list(currentApp?.id),
          exact: true,
        });

        segmentAnalytics.track("Feature Created", {
          source: feature.source,
          feature_views: (formValues.featureViews ?? []).length,
        });
        if (onSuccess) {
          onSuccess(feature);
        } else {
          navigate(FeatureUrl(currentEnv!, feature));
        }
      },
    },
    {
      defaultValues: {
        source: "event",
        name: "",
        key: "",
      },
    },
  );

  return (
    <FormProvider {...form}>
      <ModalBody>
        <NewFeatureForm id="new-feature" onSubmit={handleSubmit} />
        <FormRootError />
        <CreateAffectEnvironments entity="features" mt={8} />
      </ModalBody>
      <ModalFooter bg="transparent" justifyContent="space-between">
        {typeof footerDetails === "string" ? (
          <Text color="dimmed" fontStyle="italic">
            {footerDetails}
          </Text>
        ) : (
          <Box>{footerDetails}</Box>
        )}
        <ButtonGroup>
          {onClose ? (
            <Button
              _hover={{ color: cancelHoverColor }}
              color="gray.500"
              type="button"
              variant="ghost"
              onClick={onClose}
            >
              Cancel
            </Button>
          ) : (
            <ModalCancelButton shouldGoBack={false} />
          )}
          <FormSubmitLegacy form="new-feature">Create</FormSubmitLegacy>
        </ButtonGroup>
      </ModalFooter>
    </FormProvider>
  );
}
