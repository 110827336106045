import {
  FeatureListQuery,
  FeatureNamesQueryType,
} from "@bucketco/shared/featureAPI";

import featureViewsQueryKeys from "@/app/data/featureViewsQueryKeys";

export const featureQueryKeys = {
  list: (
    appId?: string,
    envId?: string,
    params?: Omit<FeatureListQuery, "envId">,
  ) =>
    params
      ? (["apps", appId, "environments", envId, "features", params] as const)
      : (["apps", appId, "environments", envId, "features"] as const),

  listNames: (appId?: string, params: FeatureNamesQueryType = {}) =>
    ["apps", appId, "names", params] as const,

  single: (appId?: string, envId?: string, companyId?: string) =>
    [...featureQueryKeys.list(appId, envId), companyId] as const,

  singleMetrics: (appId?: string, envId?: string, featureId?: string) =>
    [...featureQueryKeys.single(appId, envId, featureId), "metrics"] as const,

  singleMetricsCurrent: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...featureQueryKeys.singleMetrics(appId, envId, featureId),
      "current",
      params,
    ] as const,

  singleMetricsHistorical: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...featureQueryKeys.singleMetrics(appId, envId, featureId),
      "historical",
      params,
    ] as const,

  singleFeatureFeedbackHistogram: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...featureQueryKeys.single(appId, envId, featureId),
      "feedback",
      "histogram",
      params,
    ] as const,

  singleFeatureFeedbackCampaign: (
    appId?: string,
    envId?: string,
    featureId?: string,
  ) =>
    [
      ...featureQueryKeys.single(appId, envId, featureId),
      "featureFeedbackCampaigns",
    ] as const,

  keys: (appId?: string) => [appId, "featureKeys"] as const,

  singleFeatureRolloutStatus: (
    appId?: string,
    envId?: string,
    featureId?: string,
  ) =>
    [...featureQueryKeys.single(appId, envId, featureId), "rollout"] as const,

  singleFeatureCompanies: (
    appId?: string,
    envId?: string,
    featureId?: string,
    params?: Record<string, any>,
  ) =>
    [
      ...featureQueryKeys.single(appId, envId, featureId),
      "companies",
      params,
    ] as const,

  singleFeatureCompanyUsers: (
    appId?: string,
    envId?: string,
    featureId?: string,
    companyId?: string,
  ) => [
    ...featureQueryKeys.singleFeatureCompanies(appId, envId, featureId),
    companyId,
    "users",
  ],

  featureViews: (appId?: string, featureId?: string) =>
    [
      ...featureViewsQueryKeys.singleFeatureRelationViews(appId),
      featureId,
    ] as const,
};
