import { RiProhibitedLine } from "react-icons/ri";
import {
  Box,
  Card,
  CardProps,
  FlexProps,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { wrapWithFilterGroup } from "@bucketco/shared/filter";
import {
  Flag,
  FlagRule as FlagRuleDTO,
  maxRolloutThreshold,
} from "@bucketco/shared/flagAPI";
import { getFraction } from "@bucketco/shared/utils/getFraction";

import { AndOrList } from "@/common/components/AndOrList";
import {
  FilterGroup,
  SupportedFlagFilterTypes,
} from "@/common/components/filters/FilterGroup";
import PercentageNumber from "@/common/components/PercentageNumber";
import pluralize from "@/common/utils/pluralize";
import { useTargetAudienceEstimate } from "../data/useTargetAudienceEstimate";

type FlagRulesDisplayProps = {
  flag?: Flag;
  envId?: string;
  rules: FlagRuleDTO[] | undefined;
  showEstimatedTargetAudience?: boolean;
  cardProps?: CardProps;
} & FlexProps;

// TODO: Tests for the new data coming form realtime in event-status
// TODO: Figure out what is the name of the keys stored in the filter object (remove prefix from the names api)

export function FlagRulesDisplay({
  flag,
  envId,
  rules,
  showEstimatedTargetAudience = true,
  cardProps = {},
  ...flexProps
}: FlagRulesDisplayProps) {
  if (!rules || rules.length === 0) {
    return (
      <Card p={4} {...flexProps} {...cardProps}>
        <HStack color="dimmed" spacing={1.5}>
          <RiProhibitedLine />
          <Text fontSize="sm">No one has access</Text>
        </HStack>
      </Card>
    );
  }

  return (
    <AndOrList
      {...flexProps}
      conjunction="or"
      conjunctionProps={{ ml: 4, my: -1, zIndex: 1 }}
      direction="vertical"
      gap="0"
    >
      {rules.map((rule, idx) => (
        <FlagRuleDisplay
          key={String("id" in rule ? rule.id : idx)}
          envId={envId}
          flag={flag}
          rule={rule}
          showEstimatedTargetAudience={showEstimatedTargetAudience}
          {...cardProps}
        />
      ))}
    </AndOrList>
  );
}

type FlagRuleDisplayProps = {
  rule: FlagRuleDTO;
  envId?: string;
  flag?: Flag;
  showEstimatedTargetAudience?: boolean;
} & CardProps;

function FlagRuleDisplay({
  flag,
  rule,
  envId,
  showEstimatedTargetAudience = true,
  ...props
}: FlagRuleDisplayProps) {
  const rolloutFraction = getFraction(
    rule.partialRolloutThreshold ?? maxRolloutThreshold,
    maxRolloutThreshold,
  );

  const { data: estimate, isLoading: estimateLoading } =
    useTargetAudienceEstimate(
      envId,
      showEstimatedTargetAudience ? flag?.id : undefined,
      rule.id,
      {
        filter: rule.filter,
        partialRolloutThreshold: rule.partialRolloutThreshold,
      },
    );

  return (
    <Card fontSize="sm" gap={0.5} p={4} w="full" {...props}>
      <>
        <HStack align="flex-start" justify="space-between" mb={1.5}>
          <FilterGroup
            context="flag"
            showItemsIcons={true}
            types={SupportedFlagFilterTypes}
            value={wrapWithFilterGroup(rule.filter)}
            readonly
          />
          {showEstimatedTargetAudience &&
            (estimate ? (
              <HStack fontSize="sm" spacing={1}>
                <Text as="span" fontWeight="medium">
                  {Math.floor(estimate.count)}
                </Text>
                <Text as="span" color="dimmed">
                  estimated {pluralize("company", Math.floor(estimate.count))}
                </Text>
              </HStack>
            ) : (
              estimateLoading && <Spinner color="dimmed" size={"xs"} />
            ))}
        </HStack>
        <Box fontSize="sm">
          <PercentageNumber fontWeight="medium" value={rolloutFraction} />
          <Text as="span" color="dimmed">
            {" "}
            of targeted companies
          </Text>
        </Box>
      </>
    </Card>
  );
}
