import { FormEventHandler, useRef } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

import {
  AdminCreateDemoAppType,
  CreateAppScenarios,
} from "@bucketco/shared/adminAPI";
import { AccessLevel, AccessLevels } from "@bucketco/shared/organizationAPI";

import AdminOrgApps from "@/admin/components/AdminOrgApps";
import AdminOrgMembers from "@/admin/components/AdminOrgMembers";
import AdminOrgSwitcher from "@/admin/components/AdminOrgSwitcher";
import { useAdminDemoAppCreateMutation } from "@/admin/data/useAdminDemoAppCreateMutation";
import { useAdminOrgPatchMutation } from "@/admin/data/useAdminOrgPatchMutation";
import { useOrgsData } from "@/app/data/useOrgsData";
import AnimatedSpinner from "@/common/components/AnimatedSpinner";
import { DateInput } from "@/common/components/DateInput";
import { InviteKeyDisplay } from "@/global-settings/components/Team";
import { getTrialStatusLabel } from "@/global-settings/components/TrialStatus";
import { UploadLogoButton } from "@/global-settings/components/UploadLogoButton";

export default function AdminOrg() {
  const { orgId } = useParams();
  const { data: orgs = [], isLoading } = useOrgsData();
  const updateOrgMutation = useAdminOrgPatchMutation(orgId!);

  const createDemoAppDisclosure = useDisclosure();

  const currentOrg = orgs.find((o) => o.id === orgId);

  if (isLoading || !currentOrg) {
    return <AnimatedSpinner show={true} />;
  }

  const handleAccessLevelChange = (level: AccessLevel) => {
    updateOrgMutation.mutate({ accessLevel: level });
  };

  const handleTrialEndsAtDateChange = (value: Date | null) => {
    if (value instanceof Date) {
      updateOrgMutation.mutate({ trialEndsAt: value.toISOString() });
    } else {
      updateOrgMutation.mutate({ trialEndsAt: null });
    }
  };

  const handleLogoChange = (logoUrl: string | null) => {
    updateOrgMutation.mutate({ logoUrl });
  };

  const handleOrgSuspensionToggle = () => {
    updateOrgMutation.mutate({
      suspendedAt:
        currentOrg.suspendedAt === null ? new Date().toISOString() : null,
    });
  };

  return (
    <Box marginInline="auto" maxW="container.xl" paddingInline={4}>
      <Flex alignItems="center" as="header" gap={8} paddingBlock={4}>
        <Heading as="h1">
          <RouterLink to="/admin">Bucket admin</RouterLink>
        </Heading>

        <Flex alignItems="center" as="nav" gap={4} marginInlineStart="auto">
          <Flex alignItems="center" gap={2}>
            <FormLabel htmlFor="admin-org-switcher" margin="0" padding="0">
              Organization
            </FormLabel>
            <AdminOrgSwitcher
              currentOrgId={orgId}
              id="admin-org-switcher"
              size="sm"
            />
          </Flex>
        </Flex>
      </Flex>

      <Flex as="main" direction="column" gap={10}>
        <section>
          <Table>
            <colgroup>
              <col width="230" />
            </colgroup>
            <Tbody>
              <Tr>
                <Th>Organization</Th>
                <Td>
                  <Flex alignItems="center" gap={2}>
                    <Avatar
                      key={`${currentOrg.id}-${currentOrg.logoUrl}`}
                      name={currentOrg.name}
                      size="sm"
                      src={currentOrg.logoUrl ?? undefined}
                    />
                    <Text>{currentOrg.name}</Text>
                  </Flex>
                </Td>
                <Td>
                  <ButtonGroup>
                    <UploadLogoButton
                      orgId={currentOrg.id}
                      onChange={handleLogoChange}
                    >
                      {({ onClick }) => (
                        <Button variant="outline" onClick={onClick}>
                          Upload logo
                        </Button>
                      )}
                    </UploadLogoButton>
                    <Button
                      variant="outline"
                      onClick={() => handleLogoChange(null)}
                    >
                      Remove logo
                    </Button>

                    <Button
                      colorScheme="red"
                      onClick={handleOrgSuspensionToggle}
                    >
                      {currentOrg.suspendedAt === null
                        ? "Suspend"
                        : "Unsuspend"}
                    </Button>
                  </ButtonGroup>
                </Td>
              </Tr>
              <Tr>
                <Th>Created at</Th>
                <Td>{currentOrg.createdAt}</Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Access level</Th>
                <Td>
                  <Select
                    maxW={"fit-content"}
                    value={currentOrg.accessLevel}
                    onChange={(e) =>
                      handleAccessLevelChange(
                        e.currentTarget.value as AccessLevel,
                      )
                    }
                  >
                    {AccessLevels.map((level) => (
                      <option key={level} value={level}>
                        {level}
                      </option>
                    ))}
                  </Select>
                </Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Trial status</Th>
                <Td>
                  <Flex alignItems="center" gap={4}>
                    <ButtonGroup>
                      <DateInput
                        value={
                          currentOrg.trialEndsAt
                            ? currentOrg.trialEndsAt.slice(0, 10)
                            : ""
                        }
                        onChange={(e) =>
                          handleTrialEndsAtDateChange(
                            e.currentTarget.valueAsDate,
                          )
                        }
                      />
                      <Button
                        variant="outline"
                        onClick={() => handleTrialEndsAtDateChange(null)}
                      >
                        Clear
                      </Button>
                    </ButtonGroup>

                    {currentOrg.trialEndsAt && (
                      <Text>
                        {getTrialStatusLabel(new Date(currentOrg.trialEndsAt))}
                      </Text>
                    )}

                    {currentOrg.trialEndsAt ? (
                      <Badge colorScheme="orange">Trial</Badge>
                    ) : currentOrg.accessLevel != "starter" ? (
                      <Badge colorScheme="green">Customer</Badge>
                    ) : null}
                  </Flex>
                </Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Invite link</Th>
                <Td>
                  <InviteKeyDisplay
                    editable={false}
                    org={currentOrg!}
                  ></InviteKeyDisplay>
                </Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Th>Slack connection</Th>
                <Td>
                  {currentOrg.hasSlackConnection
                    ? "Connected"
                    : "Not connected"}
                </Td>
                <Td></Td>
              </Tr>
            </Tbody>
          </Table>
        </section>

        <Divider />

        <Grid gridGap={4} templateColumns="1fr 1fr">
          <GridItem as="section">
            <Flex direction="column" gap={2}>
              <Heading as="h2" fontSize="2xl">
                Apps
              </Heading>

              <AdminOrgApps org={currentOrg} />

              <Box>
                <Button
                  variant="outline"
                  onClick={createDemoAppDisclosure.onOpen}
                >
                  Create demo app
                </Button>
                <CreateDemoAppModal
                  disclosure={createDemoAppDisclosure}
                  orgId={orgId!}
                />
              </Box>
            </Flex>
          </GridItem>

          <GridItem as="section">
            <Heading as="h2" fontSize="2xl">
              Members
            </Heading>

            <AdminOrgMembers org={currentOrg} />
          </GridItem>
        </Grid>
      </Flex>
    </Box>
  );
}

function CreateDemoAppModal({
  orgId,
  disclosure,
}: {
  orgId: string;
  disclosure: ReturnType<typeof useDisclosure>;
}) {
  const createMutation = useAdminDemoAppCreateMutation(orgId!);
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    const form = e.currentTarget as HTMLFormElement;

    const values = Object.fromEntries(new FormData(form).entries());

    createMutation.mutate(values as AdminCreateDemoAppType, {
      onSuccess: () => {
        disclosure.onClose();
      },
    });
  };

  return (
    <Modal
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      onCloseComplete={() => {
        formRef.current?.reset();
      }}
    >
      <ModalOverlay />
      <form ref={formRef} onSubmit={handleSubmit}>
        <ModalContent>
          <ModalHeader>Create new demo app</ModalHeader>
          <ModalBody as={Flex} direction="column" gap={4}>
            <Box>
              <FormLabel htmlFor="demo-app-name">App name</FormLabel>
              <Input
                autoComplete="off"
                defaultValue="Slick"
                id="demo-app-name"
                isDisabled={createMutation.isPending}
                name="name"
                data-1p-ignore
              ></Input>
            </Box>

            <Box>
              <FormLabel htmlFor="demo-app-scenario">App name</FormLabel>
              <Select
                defaultValue="Slick"
                id="demo-app-scenario"
                isDisabled={createMutation.isPending}
                name="demoScenario"
              >
                {CreateAppScenarios.map((scenario) => (
                  <option key={scenario} value={scenario}>
                    {scenario}
                  </option>
                ))}
              </Select>
            </Box>
          </ModalBody>
          <ModalFooter>
            <ButtonGroup>
              <Button
                isDisabled={createMutation.isPending}
                type="button"
                variant="outline"
                onClick={disclosure.onClose}
              >
                Cancel
              </Button>
              <Button
                isLoading={createMutation.isPending}
                type="submit"
                variant="primary"
              >
                Create
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
