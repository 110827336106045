import { useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";

import { ErrorResponse } from "@bucketco/shared/api";
import { Dependee, UIFilter } from "@bucketco/shared/filter";
import { FilterCheckDetails } from "@bucketco/shared/filterAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";

import commonQueryKeys from "./commonQueryKeys";

export function useFilterCheck(dependee?: Dependee, filter?: UIFilter) {
  const { appId } = useCurrentEnv();

  return useQuery<
    FilterCheckDetails,
    AxiosError<ErrorResponse>,
    FilterCheckDetails
  >({
    queryKey: commonQueryKeys.filterCheck(appId, dependee, filter),
    queryFn: () =>
      api
        .post<"/apps/:appId/filters/check">(
          `/apps/${appId}/filters/check`,
          { filter },
          {
            params: {
              segmentId: dependee?.type === "segment" ? dependee.id : undefined,
              featureId: dependee?.type === "feature" ? dependee.id : undefined,
            },
          },
        )
        .then((res) => res.data),
    enabled: !!appId && !!dependee?.id,
  });
}

export function useCanDeleteSegment(segmentId?: string) {
  const { data, ...rest } = useFilterCheck(
    segmentId ? { type: "segment", id: segmentId } : undefined,
  );

  return {
    ...rest,
    canDelete: data?.features.length === 0 && data?.segments.length === 0,
  };
}

export function useCanDeleteFeature(featureId?: string) {
  const { data, ...rest } = useFilterCheck(
    featureId ? { type: "feature", id: featureId } : undefined,
  );

  return {
    ...rest,
    canDelete: data?.features.length === 0 && data?.segments.length === 0,
  };
}
