import { Children, cloneElement, isValidElement, useMemo } from "react";
import { Box, BoxProps, Flex, FlexProps } from "@chakra-ui/react";

export function AndOrList({
  conjunction,
  direction,
  children,
  conjunctionProps = {},
  gap = 2,
  flatten = false,
  ...props
}: Omit<FlexProps, "direction" | "flexDirection" | "flexDir"> & {
  conjunction: "and" | "or";
  direction: "horizontal" | "vertical";
  conjunctionProps?: BoxProps;
  flatten?: boolean;
}) {
  const _children = useMemo(() => {
    return Children.toArray(children).flatMap((child, idx, children) => {
      if (!isValidElement(child)) {
        throw new Error(
          "chakra-ui: Group expects children to be valid elements",
        );
      }

      const clonedChild = cloneElement(child, {
        key: `child-${child.key || idx}`,
        ...child.props,
      });

      if (children.length > idx + 1) {
        return [
          clonedChild,
          <Conjunction
            key={`conjunction-${idx}`}
            conjunction={conjunction}
            {...conjunctionProps}
            sx={
              direction === "vertical"
                ? {
                    fontWeight: "bold",
                    fontSize: "xs",
                    color: "white",
                    background: "gray.500",
                    px: 1.5,
                    rounded: "md",
                  }
                : {
                    color: "dimmed",
                    fontSize: "10px",
                    fontWeight: "semibold",
                  }
            }
          />,
        ];
      } else {
        return clonedChild;
      }
    });
  }, [children, direction, conjunction, conjunctionProps]);

  if (flatten) {
    return _children;
  }

  return (
    <Flex
      alignItems={direction === "horizontal" ? "center" : "flex-start"}
      gap={gap}
      {...props}
      flexDirection={direction === "horizontal" ? "row" : "column"}
    >
      {_children}
    </Flex>
  );
}

function Conjunction({
  conjunction,
  ...boxProps
}: {
  conjunction: "and" | "or";
} & BoxProps) {
  return (
    <Box as="span" {...boxProps}>
      {conjunction === "or" ? "OR" : "AND"}
    </Box>
  );
}
