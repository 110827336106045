import { keepPreviousData, useQuery } from "@tanstack/react-query";

import { FeedbackQuery } from "@bucketco/shared/feedbackAPI";

import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import api from "@/common/utils/api";
import feedbackQueryKeys from "@/feedback/data/feedbackQueryKeys";

export function useFeedbackData(
  params?: Omit<FeedbackQuery, "envId">,
  enabled = true,
) {
  const { appId, envId } = useCurrentEnv();
  return useQuery({
    queryKey: feedbackQueryKeys.list(appId, envId, params),
    queryFn: () =>
      api
        .get<`/apps/:appId/feedback`>(`/apps/${appId}/feedback`, {
          params: { ...params, envId: envId! },
        })
        .then((res) => res.data),
    enabled: !!appId && !!envId && enabled,
    placeholderData: keepPreviousData,
  });
}
