declare global {
  interface Window {
    env: {
      REACT_APP_API: string;
      REACT_APP_SEGMENT_WRITE_KEY: string;
      REACT_APP_ENV: string;
      REACT_APP_HEROKU_SLUG_COMMIT: string;
      REACT_APP_UPLOADIO_API_KEY: string;
      REACT_APP_UPLOADIO_ORGLOGOS_PATH: string;
      REACT_APP_CLERK_PUBLISHABLE_KEY: string;
      REACT_APP_USE_PASSPORT: string;
      [key: string]: string | undefined;
    };

    HubSpotConversations?: {
      widget?: {
        open?: () => void;
      };
    };
  }
}

// window.env is set when served by the node.js server
// process.env is set while development by webpack/CRA
const env = window?.env ?? import.meta.env ?? {};

window.env = env;

// Retrieve an environment variable, or return an empty string if it's not set
export function getEnvVar(name: string) {
  return env[name];
}

export const API_URL = env.REACT_APP_API || "http://localhost:3100/api";

export const SEGMENT_WRITE_KEY =
  env.REACT_APP_SEGMENT_WRITE_KEY || "taJPNosZVsm9AOTGtxhJPur4yd3fhPK3";

export const DEPLOY_ENV = env.REACT_APP_ENV || "development";

export const HEROKU_SLUG_COMMIT = env.REACT_APP_HEROKU_SLUG_COMMIT || "dev";

export const UPLOADIO_API_KEY =
  env.REACT_APP_UPLOADIO_API_KEY || "public_kW15ayoXfXNvRVrGrrrXN46yvbhw";

export const UPLOADIO_ORGLOGOS_PATH =
  env.REACT_APP_UPLOADIO_ORGLOGOS_PATH || "orglogos-staging";

export const DOGFOOD_TRACKING_KEY = env.REACT_APP_DOGFOOD_TRACKING_KEY;

export const CLERK_PUBLISHABLE_KEY = env.REACT_APP_CLERK_PUBLISHABLE_KEY;

export const USE_PASSPORT = (env.REACT_APP_USE_PASSPORT || "true") === "true";
