import React, { useEffect, useMemo, useRef, useState } from "react";
import { Controller, FormProvider, useFormContext } from "react-hook-form";
import { RiCheckboxCircleFill } from "react-icons/ri";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Switch,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import {
  CreateFeatureViewArgsSchema,
  CreateFeatureViewArgsType,
  FeatureViewDTO,
  PatchFeatureViewArgsSchema,
  PatchFeatureViewArgsType,
} from "@bucketco/shared/featureViewAPI";
import { FeatureViewUrl } from "@bucketco/shared/urls";

import featureViewsQueryKeys from "@/app/data/featureViewsQueryKeys";
import { useAuthContext } from "@/auth/contexts/authContext";
import {
  DeleteIconButton,
  EditIconButton,
  GoToIconButton,
} from "@/common/components/CommonIconButtons";
import FloatingFormError from "@/common/components/Form/FloatingFormError";
import FormCancel from "@/common/components/Form/FormCancel";
import FormInput from "@/common/components/Form/FormInput";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import PostToSlackNow from "@/common/components/slack/PostToSlackNow";
import { SlackChannelAutocompleteSelect } from "@/common/components/slack/SlackChannelAutocompleteSelect";
import useApiForm from "@/common/hooks/useApiForm";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useFeatureViewParam } from "@/common/hooks/useFeatureViewParam";
import { useFormFieldDiff } from "@/common/hooks/useFormFieldDiff";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import {
  CreateAffectEnvironments,
  DeleteAffectEnvironments,
} from "@/environment/components/EnvironmentCallouts";
import {
  ListLayout,
  ListLayoutProps,
} from "@/global-settings/components/GlobalSettingsHelpers";

const Layout = (props: ListLayoutProps) => (
  <ListLayout
    templateColumns="2fr 2fr minmax(80px, 1fr) minmax(150px, 1fr)"
    {...props}
  />
);

function ViewItem({ view }: { view: FeatureViewDTO }) {
  const { currentOrg, currentApp, currentEnv } = useAuthContext();
  const toast = useToast();
  const queryClient = useQueryClient();

  const inactiveColor = useColorModeValue("gray.400", "gray.600");

  const { form, handleSubmit } = useApiForm(
    (data: PatchFeatureViewArgsType) =>
      api
        .patch<"/apps/:appId/feature-views/:viewId">(
          `/apps/${currentApp?.id}/feature-views/${view.id}`,
          { ...data, name: view.isAllFeatureView ? undefined : data.name },
        )
        .then((res) => res.data),
    PatchFeatureViewArgsSchema,
    {
      onSuccess: async ({ name, slackChannel, slackWeeklyReport }) => {
        segmentAnalytics.track("Feature View Updated");
        await queryClient.invalidateQueries({
          queryKey: featureViewsQueryKeys.list(currentApp?.id),
        });
        toast({
          title: "View updated",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        form.reset({
          name: name,
          slackChannel,
          slackWeeklyReport,
        });
        setShowEditForm(false);
      },
    },
    {
      defaultValues: view,
    },
  );

  const [showEditForm, setShowEditForm] = useState(false);

  return !showEditForm ? (
    <Layout key={view.id} showAsCard>
      <Text isTruncated>{view.name}</Text>
      {currentOrg?.hasSlackConnection && currentEnv?.isProduction ? (
        <>
          <Box overflow="hidden">
            <PostToSlackNow
              isDisabled={form.formState.isSubmitting}
              notification={{
                appId: currentApp!.id,
                featureViewId: view.id,
                reportType: "featureViewReport",
              }}
              slackChannel={view.slackChannel}
            />
          </Box>
          <Box>
            {view.slackWeeklyReport ? (
              <HStack color="brand.400">
                <RiCheckboxCircleFill size="16" />
                <Box>Enabled</Box>
              </HStack>
            ) : (
              <Box color={inactiveColor}>Disabled</Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box />
          <Box />
        </>
      )}
      <Box justifySelf="end">
        <EditIconButton
          label="Edit view"
          onClick={() => setShowEditForm(true)}
        />
        {!view.isAllFeatureView && <DeleteView view={view} />}
        <GoToIconButton
          label="Open view"
          to={FeatureViewUrl(currentEnv!, view.id)}
        />
      </Box>
    </Layout>
  ) : (
    <FormProvider {...form}>
      <ViewForm
        submitLabel="Save"
        onClose={() => {
          setShowEditForm(false);
          form.reset();
        }}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
}

function DeleteView({ view }: { view: FeatureViewDTO }) {
  const { appId } = useCurrentEnv();
  const [storedViewId, updateFeatureViewParam] = useFeatureViewParam();

  const toast = useToast();
  const queryClient = useQueryClient();
  const errorToast = useErrorToast();
  const { mutate: deleteView, isPending: isDeleteLoading } = useMutation({
    mutationFn: () =>
      api.delete<"/apps/:appId/feature-views/:viewId">(
        `/apps/${appId}/feature-views/${view.id}`,
      ),

    retry: 0,

    onSuccess: async () => {
      segmentAnalytics.track("Feature View Deleted");
      if (view.id === storedViewId) {
        updateFeatureViewParam(null);
      }
      await queryClient.invalidateQueries({
        queryKey: featureViewsQueryKeys.list(appId),
      });

      // drop all cached data of relations between features and views
      queryClient.removeQueries({
        queryKey: featureViewsQueryKeys.singleFeatureRelationViews(appId),
      });

      toast({
        title: "Deleted view",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    },

    onError: () => {
      errorToast({
        description: "Couldn't delete view",
      });
    },
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);

  return (
    <>
      <DeleteIconButton
        isDisabled={isDeleteLoading}
        label="Delete view"
        onClick={onOpen}
      />
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Delete feature view</AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete <strong>{view.name}</strong> ?
              <br />
              You can&apos;t undo this action afterwards.
              <DeleteAffectEnvironments entity="feature view" mt={8} />
            </AlertDialogBody>
            <AlertDialogFooter>
              <ButtonGroup>
                <Button
                  ref={cancelRef}
                  _hover={{ color: useColorModeValue("gray.700", "gray.300") }}
                  color="gray.500"
                  isDisabled={isDeleteLoading}
                  variant="ghost"
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  isLoading={isDeleteLoading}
                  onClick={() => deleteView()}
                >
                  Delete
                </Button>
              </ButtonGroup>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

function ViewsList() {
  const { appId } = useCurrentEnv();
  const { currentOrg, currentEnv } = useAuthContext();
  const { data, isLoading } = useQuery({
    queryKey: featureViewsQueryKeys.list(appId),

    queryFn: () =>
      api
        .get<"/apps/:appId/feature-views">(`/apps/${appId}/feature-views`)
        .then((res) => res.data),
  });

  const featureViews = useMemo(() => {
    return data?.sort((a, b) => {
      if (a.isAllFeatureView) return -1;
      if (b.isAllFeatureView) return 1;
      return a.name.localeCompare(b.name);
    });
  }, [data]);

  if (isLoading) return null;

  return (
    <>
      <Layout
        color="dimmed"
        fontSize="sm"
        fontWeight="medium"
        minH="auto"
        py={1}
      >
        <Box>Name</Box>
        {currentOrg?.hasSlackConnection && currentEnv?.isProduction ? (
          <>
            <Text isTruncated>Slack channel</Text>
            <HStack gridColumnStart="span 2" overflow="hidden">
              <Text isTruncated>Weekly report</Text>
              <InfoIconTooltip text="Get a report of features in the feature view every week in Slack" />
            </HStack>
          </>
        ) : (
          <>
            <Box />
            <Box />
            <Box />
          </>
        )}
      </Layout>
      {data?.length === 0 ? (
        <Layout showAsCard>
          <Box color="gray.500" fontSize="sm">
            No feature views yet
          </Box>
        </Layout>
      ) : (
        featureViews?.map((view) => <ViewItem key={view.id} view={view} />)
      )}
    </>
  );
}

function ViewForm({
  onClose,
  onSubmit,
  submitLabel,
}: {
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  submitLabel: string;
}) {
  const { currentOrg, currentEnv } = useAuthContext();
  const form = useFormContext<PatchFeatureViewArgsType>();

  useFormFieldDiff(form, "slackChannel", ({ oldValue, newValue }) => {
    if (!oldValue && newValue) {
      form.setValue("slackWeeklyReport", true);
    }
  });

  return (
    <form onSubmit={onSubmit}>
      <Layout py={4} showAsCard>
        <Box>
          <FloatingFormError name="name">
            <Box>
              <FormInput
                autoComplete="off"
                name="name"
                placeholder="Name your view"
                showErrors={false}
                autoFocus
                data-1p-ignore
              />
            </Box>
          </FloatingFormError>
        </Box>
        {currentOrg?.hasSlackConnection && currentEnv?.isProduction ? (
          <>
            <ManagedFormControl
              name="slackChannel"
              render={({ field }) => (
                <SlackChannelAutocompleteSelect {...field} />
              )}
            />

            <Controller
              name="slackWeeklyReport"
              render={({ field }) => {
                return (
                  <Switch
                    colorScheme="brand"
                    isChecked={field.value === true}
                    name={field.name}
                    value={field.value}
                    onChange={field.onChange}
                  />
                );
              }}
            />
          </>
        ) : (
          <>
            <Box />
            <Box />
          </>
        )}
        <Box justifySelf="end">
          <FormRootError />
          <ButtonGroup>
            <FormCancel onClick={() => onClose()} />
            <FormSubmitLegacy>{submitLabel}</FormSubmitLegacy>
          </ButtonGroup>
        </Box>
      </Layout>
    </form>
  );
}

function CreateView() {
  const { appId } = useCurrentEnv();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: CreateFeatureViewArgsType) =>
      api
        .post<"/apps/:appId/feature-views">(
          `/apps/${appId}/feature-views`,
          data,
        )
        .then((res) => res.data),
    CreateFeatureViewArgsSchema,
    {
      onSuccess: async () => {
        segmentAnalytics.track("Feature View Created");
        await queryClient.invalidateQueries({
          queryKey: featureViewsQueryKeys.list(appId),
        });
        toast({
          title: "View created",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        form.reset({
          name: "",
          slackChannel: null,
          slackWeeklyReport: false,
        });
        setShowCreateForm(false);
      },
    },
    {
      defaultValues: {
        name: "",
        slackChannel: null,
        slackWeeklyReport: false,
      },
    },
  );

  const [showCreateForm, setShowCreateForm] = useState(false);

  return !showCreateForm ? (
    <Layout px={0}>
      <Box>
        <Button variant="outline" onClick={() => setShowCreateForm((c) => !c)}>
          Add view
        </Button>
      </Box>
    </Layout>
  ) : (
    <FormProvider {...form}>
      <ViewForm
        submitLabel="Create"
        onClose={() => {
          setShowCreateForm(false);
          form.reset();
        }}
        onSubmit={handleSubmit}
      />
    </FormProvider>
  );
}

export default function FeatureViews() {
  useEffect(() => {
    segmentAnalytics.page("App Feature View Settings");
  }, []);

  return (
    <Flex direction="column" gap={2} maxW="wideForm">
      <CreateAffectEnvironments entity="feature views" mb={4} />
      <ViewsList />
      <CreateView />
    </Flex>
  );
}
